body {
    background-color: #ffffff !important;
}

.techapp-header .navbar-toggler-icon {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") no-repeat 100%;
}

.navbar-toogle-techapp {
    position: fixed;
    bottom: 4px;
    border-radius: 50% !important;
    height: 50px !important;
    margin-left: 5px;
    z-index: 100000 !important;
}

.set-store-panel .k-listview {
    max-height: calc(100vh - 165px) !important;
}

.confirm-dialog .k-window {
    top: 0% !important;
    left: 0% !important;
    height: 170px !important;
}

/*#region Media: Mobile*/
@media (min-width: 320px) {
    /*.app-body {
        margin-top: 55px !important;
    }*/
    .container-fluid {
        width: 100%;
        padding-right: 5px !important;
        padding-left: 5px !important;
        margin-right: auto;
        margin-left: auto;
    }

    .select-services .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 100%;
        height: 100vh;
    }

    .list-menu-items {
        max-height: calc(100vh - 150px);
    }

    .list-technicans {
        width: 100%;
        max-height: calc(100vh - 150px);
        overflow: auto;
        margin-top: 10px;
    }

    .list-technicans .technician-item {
        float: left;
        width: 90%;
        height: 50px;
        margin-top: 5px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .list-technicans .technician-item:hover {
        cursor: pointer;
        background-color: lightgrey;
    }

    .technician-item .k-avatar-image {
        float: left;
        margin-top: 1px;
        width: 50px;
        height: 50px;
    }

    .technician-item .k-avatar-image > * {
        width: 40px !important;
        height: 40px !important;
    }

    .main-panel {
        height: calc(100vh - 145px) !important;
    }

    .main-panel .k-scheduler {
        height: calc(100vh - 160px) !important;
    }

    .alert-notification {
        width: 100%;
        right: 0px;
    }

    .k-window {
        top: 0 !important;
        left: 0 !important;
        width: 100vw !important;
        height: 100vh !important;
    }

    .btn-full {
        width: 100% !important;
        min-width: 100px !important;
        border-radius: 25px !important;
    }

    .btn-default-pos {
        width: 95% !important;
    }

    .btn-full-mobile {
        width: 100%;
        border-radius: 25px !important;
    }

    .btn-appt-book {
        width: 95% !important;
        border-radius: 25px !important;
        font-size: smaller !important;
    }

    .col-field-label {
        text-align: left;
    }

    .appts-online {
        max-height: 75vh;
        overflow: auto;
    }

    .confirm-appt-online-notificate {
        width: 100% !important;
        height: 100% !important;
    }

    .btn-blocked-time {
        bottom: 0px;
        float: right;
        margin-left: 83vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-blocked-time span {
        display: none;
    }
    /*#region Confirm Online Notification*/
    .btn-confirm-online {
        top: 8px;
        float: right;
        margin-left: 68vw;
        z-index: 10000;
        position: fixed;
        border-radius: 50% !important;
        height: 40px;
        width: 40px;
    }

    .btn-confirm-online .fa {
        font-size: 40px !important;
        margin-top: -7px;
        margin-left: -10px;
    }

    .btn-confirm-online .badge-count {
        background-color: #fa3e3e;
        border-radius: 2px;
        color: white;
        padding: 1px 3px;
        font-size: 10px;
        position: absolute;
        top: -5px;
        left: 34px;
    }
    /*#endregion*/
    .btn-copy-appt {
        bottom: 0px;
        float: right;
        margin-left: 69vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-copy-appt span {
        display: none;
    }

    .btn-move-appt {
        bottom: 0px;
        float: right;
        margin-left: 55vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-move-appt span {
        display: none;
    }

    .btn-delete-appt {
        bottom: 0px;
        float: right;
        margin-left: 41vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-delete-appt span {
        display: none;
    }

    .k-scheduler-head .k-group-horizontal .k-side-cell {
        min-width: 60px !important;
    }

    .k-scheduler-body .k-group-horizontal .k-heading-cell {
        min-width: 60px !important;
    }

    .item-sort-order-nonxl {
        display: inline-flex;
    }
    /*#region FormHeader*/
    /*.app-body {*/
    /*margin-top: 20px !important;*/
    /*margin-top: 55px !important;
    }*/
    .form-header-title {
        font-size: 25px;
        margin-left: 20vw;
    }

    .form-header-close {
        float: right;
        font-size: 30px !important;
        cursor: pointer;
    }
    /*#endregion*/
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 36vh !important;
        width: 100vw !important;
        height: 27vh !important;
    }

    .k-scheduler-navigation {
        display: none !important;
    }

    .touch-able-buttons-view-appointment-more-options .k-window {
        left: 0% !important;
        top: 35vh !important;
        width: 100vw !important;
        height: 30vh !important;
    }

    .touch-able-buttons-view-appointment-status-options .k-window {
        left: 0% !important;
        top: 35vh !important;
        width: 100vw !important;
        height: 50vh !important;
    }
    /*#region Search Customer*/
    .list-customers {
        width: 100%;
        max-height: calc(100vh - 150px);
        overflow: auto;
        margin-top: 10px;
    }

    .list-customers .customer-item {
        float: left;
        width: 95%;
        height: 50px;
        margin-top: 5px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .search-customer-info {
        float: left;
        margin-left: 55px;
        margin-top: -50px;
    }
    /*#endregion*/
}
/*#endregion*/

/*#region Media: IPhone 6,7,8 horizontal*/
@media (min-width:667px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 30vh !important;
        width: 100vw !important;
        height: 50vh !important;
    }

    .k-scheduler-navigation .k-nav-today, .k-nav-prev, .k-nav-next {
        display: none !important;
    }

    .touch-able-buttons-view-appointment-more-options .k-window {
        left: 0% !important;
        top: 20vh !important;
        width: 100vw !important;
        height: 60vh !important;
    }

    .touch-able-buttons-view-appointment-status-options .k-window {
        left: 0% !important;
        top: 20vh !important;
        width: 100vw !important;
        height: 60vh !important;
    }
}
/*#endregion*/

/*#region Media: IPhone 6,7,8 Plus horizontal*/
@media (min-width:736px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 30vh !important;
        width: 100vw !important;
        height: 40vh !important;
    }

    .k-scheduler-navigation .k-nav-today, .k-nav-prev, .k-nav-next {
        display: none !important;
    }

    .touch-able-buttons-view-appointment-more-options .k-window {
        left: 0% !important;
        top: 20vh !important;
        width: 100vw !important;
        height: 60vh !important;
    }

    .touch-able-buttons-view-appointment-status-options .k-window {
        left: 0% !important;
        top: 20vh !important;
        width: 100vw !important;
        height: 60vh !important;
    }
}
/*#endregion*/

/*#region Media: IPhone X horizontal*/
@media (min-width:812px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 30vh !important;
        width: 100vw !important;
        height: 50vh !important;
    }

    .touch-able-buttons-view-appointment-more-options .k-window {
        left: 0% !important;
        top: 20vh !important;
        width: 100vw !important;
        height: 60vh !important;
    }

    .touch-able-buttons-view-appointment-status-options .k-window {
        left: 0% !important;
        top: 20vh !important;
        width: 100vw !important;
        height: 60vh !important;
    }
}
/*#endregion*/

/*#region Media: Ipad*/
@media (min-width: 768px) {
    .k-window {
        top: 25% !important;
        left: 15% !important;
        width: 550px !important;
        height: 370px !important;
    }

    .btn-full {
        width: 100% !important;
        min-width: 100px !important;
        border-radius: 25px !important;
    }

    .main-panel {
        height: calc(100vh - 140px) !important;
    }

    .btn-blocked-time {
        bottom: 0px;
        float: right;
        margin-left: 90vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-blocked-time span {
        display: none;
    }

    /*#region Confirm Online Notification*/
    .btn-confirm-online {
        top: 8px;
        width: 40px;
        height: 40px;
        float: right;
        z-index: 10000;
        position: fixed;
        margin-left: -5vw;
        border-radius: 25px !important;
    }

    .btn-confirm-online .fa {
        margin-top: -10px;
        margin-left: -12px;
        font-size: 45px !important;
    }

    .btn-confirm-online .badge-count {
        top: 0px;
        left: 38px;
        color: white;
        font-size: 10px;
        padding: 1px 3px;
        border-radius: 2px;
        background-color: #fa3e3e;
    }
    /*#endregion*/

    .btn-copy-appt {
        bottom: 0px;
        float: right;
        margin-left: 83vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-copy-appt span {
        display: none;
    }

    .btn-move-appt {
        bottom: 0px;
        float: right;
        margin-left: 76vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-move-appt span {
        display: none;
    }

    .btn-delete-appt {
        bottom: 0px;
        float: right;
        margin-left: 69vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-delete-appt span {
        display: none;
    }

    .customer-edit-info-box .k-window {
        top: -5% !important;
        left: 0% !important;
        height: 695px !important;
    }

    .blocked-time .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 550px !important;
        height: 350px !important;
    }

    .crop-image .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 100% !important;
        height: 100% !important;
    }

    .k-scheduler-cell .k-heading-cell {
        min-width: 60px !important;
    }

    .btn-default-pos {
        max-width: 400px !important
    }

    .select-service .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 550px !important;
        height: 590px !important;
    }

    .book-confirm .k-window {
        top: -5% !important;
        left: 0% !important;
        height: 435px !important;
        width: 740px !important;
    }

    .btn-appt-book {
        width: 150px !important;
        border-radius: 25px !important;
    }

    .col-field-label {
        text-align: right;
    }

    .assign-employee .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 550px !important;
        height: 610px !important;
    }

    .confirm-appt-online .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 800px !important;
        height: 600px !important;
    }

    .appts-online {
        height: 425px !important;
        overflow: auto;
    }

    .confirm-appt-online-notificate {
        width: 85% !important;
        height: 100% !important;
    }

    /*#region Select Service and Employee*/
    .select-services .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 600px !important;
        height: 700px !important;
    }

    .select-technician .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 600px !important;
        height: 700px !important;
    }

    .security-perform .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 550px !important;
        height: 200px !important;
    }
    /*#endregion*/

    /*#region FormHeader*/
    /*.app-body {*/
    /*margin-top: 20px !important;*/
    /*margin-top: 55px !important;
    }*/

    .form-header-title {
        font-size: 30px;
        margin-left: 30vw;
    }

    .form-header-close {
        float: right;
        font-size: 35px !important;
        cursor: pointer;
    }
    /*#endregion*/

    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 40vh !important;
        width: 100vw !important;
        height: 20vh !important;
    }

    .k-scheduler-navigation {
        display: inline-flex !important;
    }
}
/*#endregion*/

/*#region Media: Ipad Horizontal*/
@media (min-width: 1024px) and (max-height: 768px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 40vh !important;
        width: 100vw !important;
        height: 28vh !important;
    }

    .k-scheduler-navigation .k-nav-today, .k-nav-prev, .k-nav-next {
        display: none !important;
    }

    .app-body {
        margin-top: 55px !important;
    }

    /*#region Confirm Online Notification*/
    .btn-confirm-online {
        top: 8px;
        width: 40px;
        height: 40px;
        float: right;
        z-index: 10000;
        position: fixed;
        margin-left: 15vw;
        border-radius: 25px !important;
    }

    .btn-confirm-online .fa {
        margin-top: -10px;
        margin-left: -12px;
        font-size: 45px !important;
    }

    .btn-confirm-online .badge-count {
        top: 0px;
        left: 38px;
        color: white;
        font-size: 10px;
        padding: 1px 3px;
        border-radius: 2px;
        background-color: #fa3e3e;
    }
    /*#endregion*/
}
/*#endregion*/

/*#region Media: Ipad Pro*/
@media (min-width: 1024px) and (max-height: 1366px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 16vh !important;
        width: 100vw !important;
        height: 16vh !important;
    }

    .k-scheduler-navigation .k-nav-today, .k-nav-prev, .k-nav-next {
        display: none !important;
    }

    .app-body {
        margin-top: 55px !important;
    }

    /*#region Confirm Online Notification*/
    .btn-confirm-online {
        top: 8px;
        width: 40px;
        height: 40px;
        float: right;
        z-index: 10000;
        position: fixed;
        margin-left: 15vw;
        border-radius: 25px !important;
    }

    .btn-confirm-online .fa {
        margin-top: -10px;
        margin-left: -12px;
        font-size: 45px !important;
    }

    .btn-confirm-online .badge-count {
        top: 0px;
        left: 38px;
        color: white;
        font-size: 10px;
        padding: 1px 3px;
        border-radius: 2px;
        background-color: #fa3e3e;
    }
    /*#endregion*/
}
/*#endregion*/

/*#region Media: Ipad Pro Horizontal*/
@media (min-width: 1366px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 44vh !important;
        width: 100vw !important;
        height: 34vh !important;
    }

    .k-scheduler-navigation .k-nav-today, .k-nav-prev, .k-nav-next {
        display: none !important;
    }

    .app-body {
        margin-top: 55px !important;
    }

    /*#region Confirm Online Notification*/
    .btn-confirm-online {
        top: 8px;
        width: 40px;
        height: 40px;
        float: right;
        z-index: 10000;
        position: fixed;
        margin-left: 35vw;
        border-radius: 25px !important;
    }

    .btn-confirm-online .fa {
        margin-top: -10px;
        margin-left: -12px;
        font-size: 45px !important;
    }

    .btn-confirm-online .badge-count {
        top: 0px;
        left: 38px;
        color: white;
        font-size: 10px;
        padding: 1px 3px;
        border-radius: 2px;
        background-color: #fa3e3e;
    }
    /*#endregion*/
}
/*#endregion*/

/*#region Media: Kindle Fire*/
@media (min-width:800px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 21vh !important;
        width: 100vw !important;
        height: 16vh !important;
    }
}
/*#endregion*/

/*#region Media: Kindle Fire Horizontal*/
@media (min-width: 1280px) {
    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 38vh !important;
        width: 100vw !important;
        height: 24vh !important;
    }

    .k-scheduler-navigation .k-nav-today, .k-nav-prev, .k-nav-next {
        display: none !important;
    }

    .app-body {
        margin-top: 55px !important;
    }

    /*#region Confirm Online Notification*/
    @-moz-document url-prefix() {
        .btn-confirm-online {
            margin-left: 30vw;
        }
    }
    /*#endregion*/
}
/*#endregion*/

/*#region Media: Specific*/
@media (min-width: 812px) {
    .book-confirm .k-window {
        top: 0% !important;
        left: 0% !important;
        height: 435px !important;
        width: 740px !important;
    }
}

@media (max-width:991.98px) {
    .app-header .navbar-brand {
        position: absolute;
        top: 0;
        left: 30% !important;
        margin-left: -77.5px;
        margin-top: 4px !important;
    }

    .select-services .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 600px;
        height: 700px;
    }

    .list-menu-items {
        max-height: calc(100vh - 150px);
    }
}

@media (min-width: 992px) {
    .k-window {
        top: 20% !important;
        left: 25% !important;
        width: 550px !important;
        height: 370px !important;
    }

    .btn-full {
        width: 100% !important;
        min-width: 100px !important;
        border-radius: 25px !important;
    }

    .btn-blocked-time {
        bottom: 0px;
        float: right;
        margin-left: 74vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-blocked-time span {
        display: none;
    }

    .btn-copy-appt {
        bottom: 0px;
        float: right;
        margin-left: 68vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-copy-appt span {
        display: none;
    }

    .btn-move-appt {
        bottom: 0px;
        float: right;
        margin-left: 62vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-move-appt span {
        display: none;
    }

    .btn-delete-appt {
        bottom: 0px;
        float: right;
        margin-left: 56vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-delete-appt span {
        display: none;
    }

    .customer-edit-info-box .k-window {
        top: 0% !important;
        left: 0% !important;
        height: 695px !important;
    }

    .blocked-time .k-window {
        top: -15% !important;
        left: 0% !important;
        width: 550px !important;
        height: 350px !important;
    }

    .crop-image .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 100% !important;
        height: 100% !important;
    }

    .k-scheduler-cell .k-heading-cell {
        min-width: 68px !important;
    }

    .btn-default-pos {
        max-width: 415px !important
    }

    .select-service .k-window {
        top: -3% !important;
        left: 0% !important;
        width: 550px !important;
        height: 590px !important;
    }

    .book-confirm .k-window {
        top: -15% !important;
        left: 0% !important;
        height: 435px !important;
        width: 740px !important;
    }

    .btn-appt-book {
        margin-left: -5px !important;
        width: 115px !important;
        border-radius: 25px !important;
    }

    .col-field-label {
        text-align: right;
    }

    .assign-employee .k-window {
        top: -3% !important;
        left: 0% !important;
        width: 550px !important;
        height: 610px !important;
    }

    .confirm-appt-online .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 800px !important;
        height: 600px !important;
    }

    .appts-online {
        height: 425px !important;
        overflow: auto;
    }

    .confirm-appt-online-notificate {
        width: 85% !important;
        height: 100% !important;
    }
    /*#region Select Service and Employee*/
    .select-services .k-window {
        top: 5% !important;
        left: 0% !important;
        width: 600px !important;
        height: 600px !important;
    }

    .select-technician .k-window {
        top: 5% !important;
        left: 0% !important;
        width: 600px !important;
        height: 600px !important;
    }

    .security-perform .k-window {
        top: 5% !important;
        left: 0% !important;
        width: 550px !important;
        height: 200px !important;
    }
    /*#endregion*/

    /*#region FormHeader*/
    .app-body {
        /*margin-top: 50px !important;*/
        margin-top: 55px !important;
    }

    .form-header-title {
        font-size: 30px;
        margin-left: 30vw;
    }

    .form-header-close {
        float: right;
        font-size: 35px !important;
        cursor: pointer;
    }
    /*#endregion*/

    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 40vh !important;
        width: 100vw !important;
        height: 20vh !important;
    }
}

@media (min-width: 1200px) {
    .btn-blocked-time {
        bottom: 0px;
        float: right;
        margin-left: 80vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-blocked-time span {
        display: none;
    }

    .btn-copy-appt {
        bottom: 0px;
        float: right;
        margin-left: 76vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-copy-appt span {
        display: none;
    }

    .btn-move-appt {
        bottom: 0px;
        float: right;
        margin-left: 72vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-move-appt span {
        display: none;
    }

    .btn-delete-appt {
        bottom: 0px;
        float: right;
        margin-left: 68vw;
        z-index: 1000;
        position: fixed;
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
    }

    .btn-delete-appt span {
        display: none;
    }

    /*#region FormHeader*/
    .app-body {
        margin-top: 55px !important;
    }

    .form-header-title {
        font-size: 25px;
        margin-left: 35vw;
    }

    .form-header-close {
        float: right;
        font-size: 28px !important;
        cursor: pointer;
    }
    /*#endregion*/

    .touch-able-buttons .k-window {
        left: 0% !important;
        top: 42vh !important;
        width: 100vw !important;
        height: 16vh !important;
    }
}
/*#endregion*/

/*#region Meida: Desktop*/
@media (min-width: 1600px) {
    .upcoming-appointments-box {
        max-height: calc(100vh - 305px);
    }

    .k-window {
        top: 25% !important;
        left: 35% !important;
        width: 550px !important;
        height: 420px !important;
    }

    /*#region Select Service and Employee*/
    .select-services .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 600px !important;
        height: 700px !important;
    }

    .select-technician .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 600px !important;
        height: 700px !important;
    }

    .select-unassign-holder .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 500px !important;
        height: 400px !important;
    }

    .list-menu-items {
        max-height: 575px;
        overflow: auto;
        margin-left: 15px;
    }

    .list-menu-items .menu-item {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .list-menu-items .menu-item:hover {
        cursor: pointer;
        background-color: lightgrey;
    }

    .list-technicans {
        width: 100%;
        max-height: 575px;
        overflow: auto;
    }

    .list-technicans .technician-item {
        float: left;
        width: 95%;
        height: 50px;
        margin-top: 5px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .list-technicans .technician-item:hover {
        cursor: pointer;
        background-color: lightgrey;
    }

    .technician-item .k-avatar-image {
        float: left;
        margin-top: 1px;
        width: 50px;
        height: 50px;
    }

    .technician-item .k-avatar-image > * {
        width: 40px !important;
        height: 40px !important;
    }
    /*#endregion*/

    .btn-full {
        width: 100% !important;
        border-radius: 25px !important;
    }

    .main-panel .k-scheduler {
        height: calc(100vh - 120px) !important;
    }

    .alert-notification {
        top: 50px;
        right: 160px;
        width: unset;
    }

    .action-buttons-bottom {
        display: none !important;
    }

    .btn-blocked-time {
        float: right;
        position: relative;
        border-radius: 25px !important;
        width: 140px !important;
        height: unset;
    }

    .btn-blocked-time span {
        display: inline;
    }

    /*#region Confirm Online Notification*/
    .btn-confirm-online {
        top: 8px;
        width: 40px;
        height: 40px;
        float: right;
        z-index: 10000;
        position: fixed;
        margin-left: 45vw;
        border-radius: 25px !important;
    }

    @-moz-document url-prefix() {
        .btn-confirm-online {
            margin-left: 25vw;
        }
    }

    .btn-confirm-online .fa {
        margin-top: -10px;
        margin-left: -12px;
        font-size: 45px !important;
    }

    .btn-confirm-online .badge-count {
        top: 0px;
        left: 38px;
        color: white;
        font-size: 10px;
        padding: 1px 3px;
        border-radius: 2px;
        background-color: #fa3e3e;
    }
    /*#endregion*/

    .btn-copy-appt {
        float: left;
        margin-left: 0px;
        position: inherit;
        border-radius: 25px !important;
        top: 3px;
        min-width: 100px;
        width: 120px;
        height: unset;
    }

    .btn-copy-appt span {
        display: inline;
    }

    .btn-move-appt {
        float: left;
        margin-left: 0px;
        position: inherit;
        border-radius: 25px !important;
        top: 3px;
        min-width: 100px;
        width: 120px;
        height: unset;
    }

    .btn-move-appt span {
        display: inline;
    }

    .btn-delete-appt {
        float: left;
        margin-left: 0px;
        position: inherit;
        border-radius: 25px !important;
        top: 3px;
        min-width: 100px;
        width: 120px;
        height: unset;
    }

    .btn-delete-appt span {
        display: inline;
    }

    .customer-edit-info-box .k-window {
        top: 0% !important;
        left: 0% !important;
        height: 695px !important;
        width: 750px !important;
    }

    .blocked-time .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 550px !important;
        height: 350px !important;
    }

    .crop-image .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 1200px !important;
        height: 100% !important;
    }

    .security-perform .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 550px !important;
        height: 200px !important;
    }

    .alert-error .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 550px !important;
        height: 200px !important;
    }

    .confirm-conflict-appts .k-window {
        top: 0% !important;
        left: 0% !important;
        height: 350px !important;
    }

    .appt-tooltip .k-window {
        top: 0% !important;
        left: 0% !important;
        height: 300px !important;
        width: 300px !important;
    }

    .k-scheduler-cell .k-heading-cell {
        min-width: 68px !important;
    }

    .btn-default-pos {
        max-width: 570px !important
    }

    .select-service .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 600px !important;
        height: 650px !important;
    }

    .book-confirm .k-window {
        top: 0% !important;
        left: 0% !important;
        height: 435px !important;
        width: 740px !important;
    }

    .btn-appt-book {
        width: 165px !important;
        border-radius: 25px !important;
    }

    .col-field-label {
        text-align: right;
    }

    .assign-employee .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 600px !important;
        height: 610px !important;
    }

    .confirm-appt-online .k-window {
        top: 0% !important;
        left: 0% !important;
        width: 800px !important;
        height: 600px !important;
    }

    .appts-online {
        height: 425px !important;
        overflow: auto;
    }

    .confirm-appt-online-notificate {
        width: 55% !important;
        height: 100% !important;
    }

    .item-sort-order-nonxl {
        display: none;
    }

    /*#region FormHeader*/
    .app-body {
        margin-top: 55px !important;
    }

    .form-header-title {
        font-size: 25px;
        margin-left: 35vw;
    }

    .form-header-close {
        float: right;
        font-size: 28px !important;
        cursor: pointer;
    }
    /*#endregion*/

    .k-scheduler-navigation {
        display: inline-flex !important;
    }

    .k-scheduler-navigation .k-nav-today, .k-nav-prev, .k-nav-next {
        display: block !important;
    }
}
/*#endregion*/

.main-form {
    width: 100%;
    max-height: calc(100vh - 190px) !important;
    overflow: auto;
}

.main-form .form-buttons {
    margin-top: 20px;
    border-top: solid 1px lightgrey;
    padding-top: 10px;
}

.form-group {
    margin-bottom: 0.5rem !important;
}

.report-wrapper {
    width: 100%;
    max-height: calc(100vh - 185px) !important;
    text-align: center;
    overflow-y: auto;
    padding-bottom: 30px;
}

.rptViewer {
    max-width: 1000px;
    border-collapse: collapse;
    margin-left: 50px;
    border: solid 1px lightgray;
}

.rptViewer td {
    padding: 5px;
}

.rptViewer .title {
    Font: bold italic 28pt Arial;
    border: 0px;
    height: 48px;
    width: 1000px;
    max-height: 48px;
    text-align: center;
    vertical-align: top;
    line-height: 1.15em;
    white-space: nowrap;
    overflow: hidden;
}

.rptViewer .header {
    Font: 8pt Arial;
    border: 0px;
    border-top-color: Black;
    border-top-style: solid;
    border-top-width: 2px;
    border-bottom-color: Black;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    text-align: left;
    vertical-align: middle;
    line-height: 1.15em;
    white-space: nowrap;
    overflow: hidden;
}

.bussiness-date {
    Font: bold 12pt Arial;
    border: 0px;
    border-top-color: Black;
    border-top-style: solid;
    border-top-width: 2px;
    text-align: left;
    vertical-align: middle;
    line-height: 1.15em;
    padding-top: 15px !important;
    white-space: nowrap;
    overflow: hidden;
}

.s12e6a6ac {
    Font: 8pt Arial;
    border: 0px;
    border-top-color: Black;
    border-top-style: solid;
    border-top-width: 2px;
    border-bottom-color: Black;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    text-align: left;
    vertical-align: top;
    line-height: 1.15em;
    white-space: nowrap;
    overflow: hidden;
}

.rptViewer tbody .row-item {
    height: 20px;
}

.row-item td {
    Font: 8pt Arial;
    border: 0px;
    text-align: left;
    vertical-align: top;
    line-height: 1.15em;
    white-space: nowrap;
    overflow: hidden;
}

.k-listview-content {
    overflow-x: hidden !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.main-panel .k-scheduler {
    height: calc(100vh - 140px) !important;
}

.main-panel .k-grid {
    height: calc(100vh - 130px);
}

.alert-notification {
    top: 50px;
    right: 160px;
    align-items: flex-start;
    flex-wrap: wrap-reverse;
}

.list-menu-items, list-technicans {
    max-height: 550px;
    overflow: auto;
    margin-left: 15px;
}

.list-menu-items .menu-item, .list-technicans .technican-item {
    margin-top: 5px;
    margin-bottom: 10px;
    padding-right: 10px;
    width: 100%;
}

.list-menu-items .menu-item:hover, .list-technicans .technican-item:hover {
    cursor: pointer;
    background-color: lightgrey;
}

.technician-box {
    width: 100% !important;
}

.technician-box .technician-name {
    float: left;
    font-weight: bold;
    width: 75%;
    line-height: 36px;
}

.select-technician .k-window-content {
    padding: 0px;
    padding-top: 10px;
}

.select-services .k-window-content {
    padding: 0px;
    padding-top: 10px;
}

.upcoming-appointments-box {
    max-height: calc(100vh - 305px);
    overflow: auto;
}

.card .header {
    line-height: 35px;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid lightgrey;
}

.upcoming-appointments-box .appt-item {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: dotted 1px lightgrey;
}

.upcoming-appointments-box .appt-item:hover {
    cursor: pointer;
    background-color: lightgrey;
}
/*#region Label*/
.wrap-label-50 {
    max-width: 50px !important;
}

.custom-label {
    color: #67768c;
    font-weight: 500;
    line-height: 35px;
}

.custom-label-bold {
    color: #212529;
    font-weight: bold;
}

center-label-bold {
    color: #212529;
    font-weight: bold;
}
/*#endregion*/
/*#region Button*/
.btn {
    margin-bottom: 5px;
    margin-right: 10px;
}

.btn-auto {
    border-radius: 25px !important;
}

.btn-auto-right {
    float: right;
    border-radius: 25px !important;
}

.btn-delete {
    background-color: red !important;
}

.btn-command {
    width: 140px;
    border-radius: 25px !important;
}

.k-button .k-i-close {
    color: #333 !important;
}

.k-button .k-i-x {
    color: #333 !important;
}

.btn-full .k-button {
    width: 100% !important;
    border-radius: 25px !important;
}

.btn-command .k-button {
    width: 140px;
    border-radius: 25px !important;
}
/*#endregion*/
/*#region DropDown*/
.dropdown-popup {
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100% !important;
}

.dropdown-popup-scrolling {
    top: 0% !important;
    left: 0% !important;
    overflow-y: auto !important;
}

.dropdown-button-popup {
    top: 3px !important;
}
/*#endregion*/
/*#region Kendo Scheduler*/
.k-scheduler-head .k-group-horizontal:last-child {
    display: none !important;
}

.k-scheduler-head .k-group-horizontal .k-side-cell {
    min-width: 70px !important;
}

.k-scheduler-body .k-group-horizontal .k-heading-cell {
    min-width: 70px !important;
}

.k-scheduler-fullday, .k-scheduler-footer {
    display: none !important;
}

/*.k-scheduler-day-view .k-scheduler-head .k-group-horizontal .k-scheduler-row {
    height: 30px;
}*/

/*.k-scheduler-layout{
    table-layout: fixed;
}

.k-scheduler-layout .k-scheduler-head, .k-scheduler-layout .k-scheduler-body {
    width: 3000px !important;
}
*/

/*.k-scheduler-layout-flex .k-group-horizontal .k-heading-cell {
    min-height: 30px !important;
    line-height: 30px !important;
    padding: 0.75em !important;
}
*/
.k-scheduler-monthview .k-scheduler-body .k-scheduler-cell {
    min-height: 100px !important;
}

.appt-unavailable {
    border: 0;
    height: 100%;
    background: repeating-linear-gradient( 45deg, #e0e0e0, #e0e0e0 4px, #ffffff 4px, #ffffff 8px );
}

.kendo-scheduler .k-event .k-event-template, .k-event .k-event-template {
    font-size: 0.8rem !important;
}

.kendo-scheduler .k-event > div, .k-event > div {
    margin-right: -20px !important;
}

.k-side-cell {
    min-width: 135px !important;
}

.k-heading-cell {
    min-width: 135px !important;
}

.k-group-cell {
    min-width: 160px !important;
}
/*.k-scheduler-toolbar {
    height: 50px !important;
}*/
/*#endregion*/
/* #region col-sm*/
.offset-sm-12 {
    margin-left: 100% !important;
}

.col-sm-12 {
    width: 100% !important;
}

.offset-sm-11 {
    margin-left: 91.66666666666666% !important;
}

.col-sm-11 {
    width: 91.66666666666666% !important;
}

.offset-sm-10 {
    margin-left: 83.33333333333334% !important;
}

.col-sm-10 {
    width: 83.33333333333334% !important;
}

.offset-sm-9 {
    margin-left: 75% !important;
}

.col-sm-9 {
    width: 75% !important;
}

.offset-sm-8 {
    margin-left: 66.66666666666666% !important;
}

.col-sm-8 {
    width: 66.66666666666666% !important;
}

.offset-sm-7 {
    margin-left: 58.333333333333336% !important;
}

.col-sm-7 {
    width: 58.333333333333336% !important;
}

.offset-sm-6 {
    margin-left: 50% !important;
}

.col-sm-6 {
    width: 50% !important;
}

.offset-sm-5 {
    margin-left: 41.66666666666667% !important;
}

.col-sm-5 {
    width: 41.66666666666667% !important;
}

.offset-sm-4 {
    margin-left: 33.33333333333333% !important;
}

.col-sm-4 {
    width: 33.33333333333333% !important;
}

.offset-sm-3 {
    margin-left: 25% !important;
}

.col-sm-3 {
    width: 25% !important;
}

.offset-sm-2 {
    margin-left: 16.666666666666664% !important;
}

.col-sm-2 {
    width: 16.666666666666664% !important;
}

.offset-sm-1 {
    margin-left: 8.333333333333332% !important;
}

.col-sm-1 {
    width: 8.333333333333332% !important;
}

.offset-sm-0 {
    margin-left: 0 !important;
}

.col-sm-0 {
    width: 0 !important;
}
/*#endregion*/
/*#region Booking Online*/
.slot {
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
    width: 100%;
    border-radius: 45px;
    background-color: bisque;
    font-weight: bold;
}

.slot:hover {
    background-color: #0069D9;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.slot-active {
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
    width: 100%;
    border-radius: 45px;
    background-color: #0069D9;
    font-weight: bold;
    color: white;
}

.wide-social {
    width: 100% !important;
    border: none;
}

.bolder-bold {
    border: 1px solid #ddd;
}

.fb {
    background-color: #3B5998 !important;
    color: white !important;
    text-align: left !important;
    max-width: 200px;
    height: 45px;
}

.google {
    background-color: #dd4b39 !important;
    color: white !important;
    text-align: left !important;
    max-width: 200px;
    height: 45px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.vl {
    position: absolute;
    left: 40%;
    transform: translate(-50%);
    border: 2px solid #ddd;
    height: 360px;
}

.vl-innertext {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 35%;
    padding: 3px 3px;
}

.appt-booked {
    cursor: pointer;
}

.appt-booked .avatar-box {
    margin-right: 50px;
    width: 50px;
    float: left;
}

.appt-booked .avatar-box .avatar {
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background-color: bisque;
    font-weight: bold;
}

.appt-booked .detail {
    float: left;
    margin-top: 10px;
}

.appt-booked .detail .name {
    font-weight: bold;
}

.appt-booked .detail .service {
    font-style: italic;
    font-size: smaller;
}

.noteAlt {
    background-color: #f0f7fb;
    border-left: solid 4px #3498db;
    overflow: hidden;
    padding-left: 5px;
    height: 30px;
}
/*#endregion*/
/* #region Captcha*/
.rnc {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*max-width: 255px;*/
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
}

.rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 10px 0;
    height: 40px;
}

.rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 10px;
}

.rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
}

.rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
}

.rnc-button svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
    height: 30px;
}
/*#endregion*/
/* #region Report*/
.report-title {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    font-style: italic;
}

table, td, th {
    border: 1px solid lightgrey;
    text-align: left;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 15px;
}
/* #endregion*/
/*#region Kendo DatePicker*/
.read-only-date-picker .k-widget .k-dateinput {
    pointer-events: none;
}
/*#endregion*/
/*#region UserProfile*/
.nav-profile-user {
    border-radius: 5px !important;
    background-color: #FA15B9;
    margin-right: 12px;
    /*margin-top: -10px;*/
    height: 55px;
}

.profile-user {
    cursor: pointer;
    margin-top: 0px;
}

.profile-user .avatar-box {
    float: left;
    margin-left: 5px;
}

.profile-user .avatar-box .avatar {
    text-align: center;
    vertical-align: middle;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background-color: bisque;
    font-weight: bold;
    margin-top: 5px;
}

.profile-user .detail {
    margin-top: 0px;
    /*min-width: 150px;*/
    text-align: left;
}

.profile-user .detail .text {
    color: #ffffff;
    margin-left: 10px;
}

.dropdown-menu-right {
    width: 100%;
    background-color: #F0F8FF;
    transform: translate3d(0px, 45px, 0px) !important;
}

.profile-user-mobile {
    cursor: pointer;
    margin-top: 0px;
}

.profile-user-mobile .avatar-box-mobile {
    float: right;
}

.avatar-mobile {
    text-align: center;
    vertical-align: middle;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    background-color: bisque;
    font-weight: bold;
}
/*#endregion*/
/*#region Appointment*/
.cardShadow {
    -webkit-box-shadow: 0px 0px 3px 3px #E4E7EB;
    box-shadow: 0px 0px 3px 3px #E4E7EB;
}

.customer-list-item {
    cursor: pointer;
}

.customer-list-item:hover {
    background-color: lightblue;
}

.customer-list-item .item-box {
    padding: 8px 14px;
    margin-bottom: 4px;
    box-shadow: 0 1px 2px #ccc;
}

.customer-list-item .full-name {
    font-size: 1.3em;
    font-weight: bold;
    margin: 0
}

.customer-info-box {
    height: 90px;
    cursor: pointer;
}

.customer-info-box .avatar-box {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    float: left;
}

.customer-info-box .avatar-box .avatar {
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    width: 50px;
    height: 50px;
    border-radius: 45px;
    background-color: bisque;
    font-weight: bold;
}

.customer-info-box .detail {
    float: left;
    margin-top: 10px;
}

.customer-info-box .detail .name {
    font-weight: bold;
}

.customer-info-box .detail .email, .phone {
    font-style: italic;
    font-size: smaller;
}

.customer-info-box .command-buttons {
    float: right;
    width: 39px;
    height: 90px;
    vertical-align: middle;
}

.customer-info-box .command-mobile {
    float: right;
    width: 39px;
    height: 90px;
    vertical-align: middle;
}

.customer-info-hash-tag {
    width: 100%;
    height: 35px;
    color: #FFFFFF;
    padding-top: 5px;
    text-align: center;
    border-radius: 25px;
    display: inline-block;
    vertical-align: middle;
    background-color: #0069D9;
    box-shadow: 1px 1px 8px #333333;
}

.employee {
    float: left;
    width: 200px;
    height: 200px;
    cursor: pointer;
    margin: 10px;
    background-color: #07608a;
    color: #ffffff;
}

.employee .initials {
    width: 100%;
    height: 140px;
    display: block;
    text-align: center;
    line-height: 140px;
    font-size: 50pt;
    background-color: rgb(42, 43, 109);
    color: rgb(255, 255, 255);
}

.employee .img {
    float: left;
    width: 100%;
    height: 140px;
    cursor: pointer;
}

.employee .info {
    float: left;
    width: 100%;
}

.employee .img img {
    width: 100%;
    height: 100%;
}

.employee .name {
    width: 100%;
    height: 30px;
    color: #ffffff;
    padding: 5px;
    font-size: .9em;
    overflow: hidden;
    font-weight: normal;
    float: left;
    text-transform: uppercase;
    vertical-align: middle;
}

.employee .timeinfo {
    padding-left: 5px;
    font-size: smaller;
    float: left;
    margin-top: 5px;
    font-style: italic;
}

.customKendoInputWidth {
    width: 100% !important;
}

.item-sort-order-xl {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: transparent;
    color: rgb(63, 81, 181);
    font-weight: bold;
    border: 2px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.item-sort-order-nonxl {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: transparent;
    color: rgb(63, 81, 181);
    font-weight: bold;
    border: 2px solid;
    align-items: center;
    justify-content: center;
}

.service-item-row {
    padding-left: 20px;
    padding-right: 20px;
}

.service-item-row-label {
    padding-bottom: 0px;
    float: left;
    max-width: 50%;
}
/*#region Notification Online Appointment*/
.badge-wrapper {
    color: white;
    display: inline-block;
    position: relative;
    padding: 2px 5px;
}

.badge-count {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;
    padding: 1px 3px;
    font-size: 10px;
    position: absolute;
    top: 0;
}
/*#endregion*/
/*#endregion*/
/*#region Dashboard*/
.dashboard-item {
    padding: 5px;
    height: 100%;
    border-style: ridge;
    border-width: 1px;
    border-color: #E4E7EB;
}

.dashboard-item .icon-box {
    margin-right: 25px;
    width: 50px;
    float: left;
}

.dashboard-item .icon-box .icon-item {
    text-align: center;
    vertical-align: middle;
    line-height: 70px;
    width: 70px;
    height: 70px;
    border-radius: 45px;
    background-color: bisque;
    font-weight: bold;
}

.dashboard-item .detail {
    float: left;
    margin-top: 0px;
}

.dashboard-item .detail .count {
    font-size: 22px;
    font-weight: bold;
}

.dashboard-item .detail .name {
    color: #67768c;
    font-weight: 300;
}
/*#endregion*/
.app-header {
    border: 0;
    top: -5px;
    height: 60px;
    background-color: #2F353A !important;
}

.navbar-toggler {
    background-color: #2F353A !important;
}

.app-header .navbar-toggler:hover .navbar-toggler-icon {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") no-repeat 100%;
}

.busy-overlay {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    text-align: center;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
    opacity: 0.5;
    z-index: 1000;
    background-color: white;
}

.busy-overlay div.spinner {
    margin-top: 17%;
}

.k-textbox-lng {
    width: 90% !important;
}

.appts-employee-info {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    display: block;
    position: absolute;
}

.k-tabstrip .k-tabstrip-items {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-x: auto;
    font-size: 12px;
    background-color: #E8EAF6;
}

.k-tabstrip-items .k-item {
    font-weight: bold;
    color: #333 !important;
}

.k-grid-content tr[class*="k-master-row"] {
    cursor: pointer;
}

.bgTransparent {
    background-color: transparent !important;
}

.error {
    margin-left: 5px;
    font-size: 13px;
    color: red;
}

.wide-full {
    width: 100% !important;
}

.k-animation-container {
    z-index: 10003 !important; //Set the desired value based on the application requirements
}

.k-tabstrip .k-animation-container {
    width: 100% !important;
}

.k-window .k-i-window-maximize, .k-window .k-i-window-minimize {
    display: none;
}

.k-heading-cell {
    position: relative;
}

.required {
    border: 1px solid red !important;
}

.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}

.img-preview {
    overflow: hidden;
}

.active-status {
    text-align: center;
    background-color: #4caf50;
    color: #fff;
    border: solid 1px #4caf50;
    border-radius: 25px;
}

.inactive-status {
    text-align: center;
    background-color: #eff1fc;
    color: #333;
    border: solid 1px #eff1fc;
    border-radius: 25px;
}

.k-reset {
    overflow: hidden;
}

/*#region Header Appointment Book Online*/
.book-online-header {
    cursor: pointer;
}

.book-online-header .logo-box {
    margin-right: 50px;
    width: 275px;
    float: left;
}

.book-online-header .logo-box .logo {
    text-align: center;
    vertical-align: middle;
    line-height: 160px;
    width: 300px;
    height: 160px;
    border-radius: 45px;
    /*background-color: bisque;*/
    font-weight: bold;
}

.book-online-header .detail {
    float: left;
    margin-top: 10px;
}

.book-online-header .detail .store-name {
    font-weight: bold;
}

.book-online-header .detail .store-info {
    font-style: italic;
    font-size: smaller;
}
/*#endregion*/
